@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Playfair Display', sans-serif;
}

.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

a:hover {
  cursor: pointer;
}

#main-content {
  min-height: 100vh;
  margin-left: 0px;
  margin-right: 0px;
}