img {
    height: 90%;
    width: 90%;
}

#master {
    @media (max-width: 390px) {
        display: flex;
        justify-content: center;
        text-align: center;
    }
}


.fade-in-text {
    display: inline-block;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    animation: fadeIn linear 4s;
    -webkit-animation: fadeIn linear 4s;
    -moz-animation: fadeIn linear 4s;
    -o-animation: fadeIn linear 4s;
    -ms-animation: fadeIn linear 4s;
}

.fade-in-image {
    animation: fadeInSlow 4s;
    -webkit-animation: fadeInSlow 4s;
    -moz-animation: fadeInSlow 4s;
    -o-animation: fadeInSlow 4s;
    -ms-animation: fadeInSlow 4s;
}

.fade-in-text-third {
    display: inline-block;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    animation: fadeInThird linear 5s;
    -webkit-animation: fadeInThird linear 5s;
    -moz-animation: fadeInThird linear 5s;
    -o-animation: fadeInThird linear 5s;
    -ms-animation: fadeInThird linear 5s;
}

@keyframes fadeIn {
    0% {opacity:0;}
    50% {opacity:1;}
}

@-moz-keyframes fadeIn {
    0% {opacity:0;}
    50% {opacity:1;}
}

@-webkit-keyframes fadeIn {
    0% {opacity:0;}
    50% {opacity:1;}
}

@-o-keyframes fadeIn {
    0% {opacity:0;}
    50% {opacity:1;}
}

@-ms-keyframes fadeIn {
    0% {opacity:0;}
    50% {opacity:1;}
}


/*These next animations will be for the image*/

@keyframes fadeInSlow {
    0% {opacity:0;}
    40% {opacity: 0;}
    90% {opacity:1;}
}

@-moz-keyframes fadeInSlow {
    0% {opacity:0;}
    40% {opacity: 0;}
    90% {opacity:1;}
}

@-webkit-keyframes fadeInSlow {
    0% {opacity:0;}
    40% {opacity: 0;}
    90% {opacity:1;}
}

@-o-keyframes fadeInSlow {
    0% {opacity:0;}
    40% {opacity: 0;}
    90% {opacity:1;}
}

@-ms-keyframes fadeInSlow {
    0% {opacity:0;}
    40% {opacity: 0;}
    90% {opacity:1;}
}

/*These next animations will be for the third item*/
@keyframes fadeInThird {
    0% {opacity:0;}
    50% {opacity: 0;}
    90% {opacity:1;}
}

@-moz-keyframes fadeInThird {
    0% {opacity:0;}
    50% {opacity: 0;}
    90% {opacity:1;}
}

@-webkit-keyframes fadeInThird {
    0% {opacity:0;}
    50% {opacity: 0;}
    90% {opacity:1;}
}

@-o-keyframes fadeInThird {
    0% {opacity:0;}
    50% {opacity: 0;}
    90% {opacity:1;}
}

@-ms-keyframes fadeInThird {
    0% {opacity:0;}
    50% {opacity: 0;}
    90% {opacity:1;}
}